<template>
  <nav-bar />
  <div class="container">
    <div>
      <router-link
        :to="{ name: 'ViewCategories', params: { locationId: locationId } }"
      >
        <button class="btn btn-success float-start">
          View Category
        </button></router-link
      >
      <router-link
        :to="{ name: 'AddNewItem', params: { locationId: locationId } }"
      >
        <button class="btn btn-success float-end" v-if="numOfCategories > 0">
          Add New Item
        </button></router-link
      >
    </div>
    <div class="clearfix"></div>
    <div class="text-center">
      <h1 class="mb-0">{{ locName }}</h1>
      <p class="text-muted">{{ locAddress }}</p>
    </div>
    <div class="clearfix"></div>
    <router-link
      v-if="listOfUserCategories.length > 0"
      :to="{ name: 'DeleteAllItems', params: { locationId: locationId } }"
    >
      <button class="btn btn-danger">Delete All Items</button></router-link
    >
    <div class="clearfix"></div>
    <br />
    <div class="each-category">
      <div v-for="(cat, i) in listOfUserCategories" :key="i">
        <div class="row">
          <div class="row col-12 catName">
            <h3 class="text-center bg-light p-1">{{ cat.name }}</h3>
          </div>
          <div
            v-for="(item, h) in listOfItems"
            :key="h"
            v-show="cat.id == item.catId"
            :class="{
              'col-xs-12 col-sm-4 px-4': cat.id == item.catId,
            }"
          >
            <div
              v-if="cat.id == item.catId"
              :class="{
                'each-item': cat.id == item.catId,
              }"
            >
              <h4 class="item-name">{{ item.name }}</h4>
              <p class="item-description text-muted">
                {{ item.description }}
              </p>
              <div>
                <div class="available-items float-start text-muted">
                  available Qty:{{ item.qty }}
                </div>
                <div class="item-price float-end text-muted">
                  Price: {{ item.price }}
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="p-1 mt-2">
                <div class="float-start">
                  <router-link
                    :to="{
                      name: 'UpdateItem',
                      params: { locationId: locationId, itemId: item.id },
                    }"
                  >
                    <button class="btn btn-info float-end">
                      Update
                    </button></router-link
                  >
                </div>
                <div class="float-end">
                  <router-link
                    :to="{
                      name: 'DeleteItem',
                      params: { locationId: locationId, itemId: item.id },
                    }"
                  >
                    <button class="btn btn-danger float-end">
                      Delete
                    </button></router-link
                  >
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <hr />
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "LocationMenu",
  components: {
    NavBar,
  },
  data() {
    return {
      userId: "",
      userName: "",
      locationId: this.$route.params.locationId,
      locName: "",
      locAddress: "",
      listOfUserCategories: [],
      listOfItems: [],
    };
  },
  computed: {
    ...mapState([
      "isUserLoggedIn",
      "loggedInUserId",
      "numOfCategories",
      "listOfCategories",
    ]),
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.userName = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
      this.isLoggedInUser();
      // this.locationId = this.$route.params.locationID;
      this.displayAllCategories({
        userIdIs: this.userId,
        locationIdIs: this.locationId,
      });
      this.canUserAccessThisLocation({
        userIdIs: this.userId,
        locationIdIs: this.locationId,
        redirectToPage: "home",
      });
    }
    this.getLocationInfo(this.userId, this.locationId);
    this.getCurrentUserCategories(this.userId, this.locationId);
    this.getCurrentUserItems(this.userId, this.locationId);
  },
  methods: {
    ...mapMutations([
      "isLoggedInUser",
      "displayAllCategories",
      "canUserAccessThisLocation",
    ]),
    ...mapActions(["redirectTo"]),
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `http://localhost:3000/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200 && result.data.length > 0) {
        locDetails = result.data;
        this.locName = locDetails[0].name;
        this.locAddress = locDetails[0].address;
      }
    },
    async getCurrentUserCategories(userId, locationId) {
      let result = await axios.get(
        `http://localhost:3000/categories?userId=${userId}&locationId=${locationId}`
      );
      if (result.status == 200) {
        this.listOfUserCategories = result.data;
        console.table(this.listOfUserCategories);
      }
    },
    async getCurrentUserItems(userId, locationId) {
      let result = await axios.get(
        `http://localhost:3000/items?userId=${userId}&locaId=${locationId}`
      );
      if (result.status == 200) {
        this.listOfItems = result.data;
        console.table(this.listOfItems);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.item-description {
  padding-top: 12px;
  min-height: 100px;
}
</style>
