<template>
  <div class="container">
    <NavBar />
    <p class="text-end">
      Welcome {{ userName }}
      <router-link :to="{ name: 'Profile' }">
        <button class="btn btn-info">Profile</button>
      </router-link>
    </p>
    <router-link :to="{ name: 'AddNewLocation' }">
      <button type="button" class="btn btn-primary">Add New Restaurant</button>
    </router-link>
    <user-locations :allLocations="listOfLocations" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UserLocations from "@/components/UserLocations.vue";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      userName: "",
      userId: "",
      listOfLocations: [],
    };
  },
  components: {
    NavBar,
    UserLocations,
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.userName = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
    }
    let result = await axios.get(
      `http://localhost:3000/locations?userId=${this.userId}`
    );
    if (result.status == 200 && result.data.length > 0) {
      this.listOfLocations = result.data;
    }
  },
  methods: {
    ...mapActions(["redirectTo"]),
  },
};
</script>
