import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignUp from "../views/SignUp.vue";
import LogIn from "../views/LogIn.vue";
import Profile from "../views/Profile.vue";
import ErrorPage from "../views/ErrorPage.vue";
import UpdateProfile from "../components/UpdateProfile.vue";
import AddNewLocation from "../components/AddNewLocation.vue";
import DeleteLocation from "../components/DeleteLocation.vue";
import DeleteAllLocations from "../components/DeleteAllLocations.vue";
import UpdateLocation from "../components/UpdateLocation.vue";
import LocationMenu from "../components/LocationMenu.vue";
import AddNewCategory from "../components/AddNewCategory.vue";
import UpdateCategory from "../components/UpdateCategory.vue";
import DeleteCategory from "../components/DeleteCategory.vue";
import ViewCategories from "../components/ViewCategories.vue";
import DeleteAllCategories from "../components/DeleteAllCategories.vue";
import AddNewItem from "../components/AddNewItem.vue";
import UpdateItem from "../components/UpdateItem.vue";
import DeleteItem from "../components/DeleteItem.vue";
import DeleteAllItems from "../components/DeleteAllItems.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/log-in",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/update-profile",
    name: "UpdateProfile",
    component: UpdateProfile,
  },
  {
    path: "/add-new-location",
    name: "AddNewLocation",
    component: AddNewLocation,
  },
  {
    path: "/update-location/:locationId",
    name: "UpdateLocation",
    component: UpdateLocation,
  },
  {
    path: "/delete-location/:locationId",
    name: "DeleteLocation",
    component: DeleteLocation,
  },
  {
    path: "/delete-all-locations",
    name: "DeleteAllLocations",
    component: DeleteAllLocations,
  },
  {
    path: "/location-menu/:locationId",
    name: "LocationMenu",
    component: LocationMenu,
  },
  {
    path: "/location-menu/categories/view/:locationId",
    name: "ViewCategories",
    component: ViewCategories,
  },
  {
    path: "/location-menu/add-new-item/loc/:locationId",
    name: "AddNewItem",
    component: AddNewItem,
  },
  {
    path: "/location-menu/update-item/loc/:locationId/item/:itemId",
    name: "UpdateItem",
    component: UpdateItem,
  },
  {
    path: "/location-menu/delete-item/loc/:locationId/item/:itemId",
    name: "DeleteItem",
    component: DeleteItem,
  },
  {
    path: "/location-menu/delete-all/:locationId/",
    name: "DeleteAllItems",
    component: DeleteAllItems,
  },
  {
    path: "/location-menu/categories/delete/all/:locationId",
    name: "DeleteAllCategories",
    component: DeleteAllCategories,
  },
  {
    path: "/location-menu/categories/add/:locationId",
    name: "AddNewCategory",
    component: AddNewCategory,
  },
  {
    path: "/location-menu/categories/update/loc/:locationId/item/:catId",
    name: "UpdateCategory",
    component: UpdateCategory,
  },
  {
    path: "/location-menu/categories/Delete/loc/:locationId/item/:catId",
    name: "DeleteCategory",
    component: DeleteCategory,
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to.params.pageTitle);
  if (to.params.pageTitle !== undefined) {
    console.log(to.params.pageTitle);
    document.title = `${to.name} | ${to.params.pageTitle} | ${process.env.VUE_APP_TITLE} `;
  } else {
    if (to.name == null) {
      document.title = `Unknow Page | ${process.env.VUE_APP_TITLE}`;
    } else {
      document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`;
    }
  }
  next();
});
export default router;
