<template>
  <div>
    <ProfileForm />
  </div>
</template>
<script>
import ProfileForm from "@/components/ProfileForm.vue";
export default {
  name: "ProfileView",
  components: {
    ProfileForm,
  },
};
</script>
