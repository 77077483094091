<template>
  <div>
    <NavBar />
    <div class="container">
      <router-link
        :to="{ name: 'AddNewCategory', params: { locationId: locationId } }"
      >
        <button class="btn btn-info float-start">
          Add Category
        </button></router-link
      >
      <router-link
        :to="{ name: 'LocationMenu', params: { locationId: locationId } }"
      >
        <button class="btn btn-success float-start">
          Back To Menu
        </button></router-link
      ><br />
      <div class="text-center">
        <h1 class="mb-0">{{ locName }}</h1>
        <p class="text-muted">{{ locAddress }}</p>
      </div>
      <table class="table caption-top" v-if="numOfCategories > 0">
        <caption>
          <span>
            List OF Categories:
            {{ numOfCategories }}
          </span>
          <span class="float-end">
            <router-link
              :to="{
                name: 'DeleteAllCategories',
                params: { locationId: locationId },
              }"
            >
              <button class="btn btn-danger">Delete All</button>
            </router-link>
          </span>
        </caption>
        <thead class="table-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cat, i) in listOfCategories" :key="i">
            <td scope="row">{{ cat.name }}</td>
            <td>
              <router-link
                :to="{
                  name: 'UpdateCategory',
                  params: { catId: cat.id, locationId: cat.locationId },
                }"
              >
                <button class="btn btn-info">Update</button> </router-link
              >&nbsp;
              <router-link
                :to="{
                  name: 'DeleteCategory',
                  params: { catId: cat.id, locationId: cat.locationId },
                }"
              >
                <button class="btn btn-danger">Delete</button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-warning mt-5" role="alert">
        No Categories Added Yet
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../components/NavBar.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  name: "ViewCategories",
  components: {
    NavBar,
  },
  data() {
    return {
      locationId: this.$route.params.locationId,
      userId: "",
      locName: "",
      locAddress: "",
    };
  },
  computed: {
    ...mapState([
      "isUserLoggedIn",
      "loggedInUserId",
      "numOfCategories",
      "listOfCategories",
    ]),
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.userName = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
      this.canUserAccessThisLocation({
        userIdIs: this.userId,
        locationIdIs: this.locationId,
        redirectToPage: "home",
      });
    }
    this.displayAllCategories({
      userIdIs: this.userId,
      locationIdIs: this.locationId,
    });
    this.getLocationInfo(this.userId, this.locationId);
  },
  methods: {
    ...mapMutations([
      "isLoggedInUser",
      "displayAllCategories",
      "canUserAccessThisLocation",
    ]),
    ...mapActions(["redirectTo"]),
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `http://localhost:3000/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200 && result.data.length > 0) {
        locDetails = result.data;
        this.locName = locDetails[0].name;
        this.locAddress = locDetails[0].address;
      }
    },
  },
};
</script>
