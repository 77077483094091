<template>
  <div>
    <div class="text-center">
      <router-link :to="{ name: 'home' }">
        <button class="btn btn-info">Visit Home Page</button>
      </router-link>
    </div>
    <h3 class="mx-5">Page is not found</h3>
  </div>
  <!-- <img src="../assets/pageNotFound.jpg" alt="Page Not Found" class="rounded mx-auto d-block" /> -->
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>

<style></style>
