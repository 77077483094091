<template>
  <div class="container">
    <NavBar />
    <div class="clearfix"></div>
    <router-link
      :to="{ name: 'LocationMenu', params: { locationId: locationId } }"
    >
      <button class="btn btn-info float-start">
        Back To Menu
      </button></router-link
    >
    <div class="clearfix"></div>
    <form @click.prevent>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div class="clearfix"></div>
          <div class="text-center">
            <h1 class="mb-0">{{ locName }}</h1>
            <p class="text-muted">{{ locAddress }}</p>
          </div>
          <div class="clearfix"></div>
          <p class="text-danger">
            Are you sure!,Do you want to delete this item?
          </p>
          <h1>Item Name: {{ itemName }}</h1>
          <hr />
        </div>
      </div>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <button class="btn btn-info" @click="goBack()">Go Back</button
          >&nbsp;&nbsp;&nbsp;<button
            class="btn btn-danger"
            @click="deleteItem()"
          >
            Delete Now
          </button>
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div
            class="col-auto d-block mx-auto alert alert-warning"
            v-if="successMessage.length > 0"
          >
            {{ successMessage }}
          </div>
          <div
            class="col-auto d-block mx-auto alert alert-danger"
            v-if="errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "DeleteItem",
  components: {
    NavBar,
  },
  data() {
    return {
      locationId: this.$route.params.locationId,
      itemId: this.$route.params.itemId,
      userId: "",
      locationData: [],
      successMessage: "",
      errorMessage: "",
      locName: "",
      locAddress: "",
      allItemsIdIs: [],
      itemName: "",
      itemPrice: "",
      quantity: 1,
      description: "",
      pickedCategory: "",
    };
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.deleteLocation = this.$route.params.locationId;
      this.userId = JSON.parse(user).id;
      this.canCurrentUserAccessThisLocation();
      this.canUserAccessThisItem({
        userIdIs: this.userId,
        locationIdIs: this.locationId,
        itemIdIs: this.itemId,
        redirectToPage: "home",
      });
      this.getItemInfo(this.userId, this.locationId, this.itemId);
      this.getCategoryName(this.userId, this.locationId, this.catId);
      let result = await axios.get(
        `http://localhost:3000/items?catId=${this.catId}`
      );
      console.log(result.data.length);
      let resultlen = result.data.length;
      for (var i = 0; i < resultlen; i++) {
        this.allItemsIdIs.push(result.data[i].id);
      }
    }
  },
  methods: {
    ...mapMutations(["canUserAccessThisItem"]),
    ...mapActions(["redirectTo"]),
    goBack() {
      this.$router.push({
        name: "LocationMenu",
        params: { locationId: this.locationId },
      });
    },
    async canCurrentUserAccessThisLocation() {
      let result = await axios.get(
        `http://localhost:3000/locations?id=${this.deleteLocation}&userId=${this.userId}`
      );
      if (result.status == 200 && result.data.length > 0) {
        this.locationData = result.data;
        this.locName = this.locationData[0].name;
        this.locAddress = this.locationData[0].address;
      } else {
        this.redirectTo({ val: "home" });
      }
    },
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `http://localhost:3000/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200) {
        locDetails = result.data;
        if (result.data.length > 0) {
          this.locName = locDetails[0].name;
          this.locAddress = locDetails[0].address;
        }
      }
    },
    async getItemInfo(userId, locationId, itemId) {
      let result = await axios.get(
        `http://localhost:3000/items?userId=${userId}&locId=${locationId}&id=${itemId}`
      );
      let resultData = result.data[0];
      if (result.status == 200) {
        this.description = resultData.description;
        this.itemName = resultData.name;
        this.quantity = resultData.qty;
        this.itemPrice = resultData.price;
        this.pickedCategory = resultData.catId;
      }
    },
    async getCategoryName(userId, locId, catId) {
      let result = await axios.get(
        `http://localhost:3000/categories?userId=${userId}&locationId=${locId}&id=${catId}`
      );
      if (result.status == 200) {
        this.categroyName = result.data;
        if (this.categroyName.length > 0) {
          this.name = this.categroyName[0].name;
        }
      }
    },
    async deleteItem() {
      let result = await axios.delete(
        `http://localhost:3000/items/${this.itemId}`
      );
      if (result.status == 200) {
        this.successMessage = " item is deleted ..";
        setTimeout(() => {
          this.$router.push({
            name: "LocationMenu",
            params: { locationId: this.locationId },
          });
        }, 1000);
      } else {
        console.log("Something went error ");
        this.errorMessage = "Something went error";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
