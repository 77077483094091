<template>
  <div class="container">
    <NavBar />
    <div class="clearfix"></div>
    <router-link
      :to="{ name: 'LocationMenu', params: { locationId: locationId } }"
    >
      <button class="btn btn-info float-start">
        Back To Menu
      </button></router-link
    >
    <div class="clearfix"></div>
    <form @click.prevent>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div class="clearfix"></div>
          <div class="text-center">
            <h1 class="mb-0">{{ locName }}</h1>
            <p class="text-muted">{{ locAddress }}</p>
          </div>
          <div class="clearfix"></div>
          <p class="text-danger">
            Are you sure!,Do you want to delete all items for aboelocation?
          </p>
          <hr />
        </div>
      </div>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <button class="btn btn-info" @click="goBack()">Go Back</button
          >&nbsp;&nbsp;&nbsp;<button
            class="btn btn-danger"
            @click="deleteAllItems()"
          >
            Delete Now
          </button>
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div
            class="col-auto d-block mx-auto alert alert-warning"
            v-if="successMessage.length > 0"
          >
            {{ successMessage }}
          </div>
          <div
            class="col-auto d-block mx-auto alert alert-danger"
            v-if="errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "DeleteAllItems",
  components: {
    NavBar,
  },
  data() {
    return {
      locationId: this.$route.params.locationId,
      userId: "",
      locationData: [],
      successMessage: "",
      errorMessage: "",
      locName: "",
      locAddress: "",
      allItemsIdIs: [],
      myResult: "",
    };
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.deleteLocation = this.$route.params.locationId;
      this.userId = JSON.parse(user).id;
      this.canCurrentUserAccessThisLocation();
      let result = await axios.get(
        `http://localhost:3000/items?userId=${this.userId}&locId=${this.locationId}`
      );
      this.myResult = result.data;
      let resultlen = this.myResult.length;
      for (var i = 0; i < resultlen; i++) {
        this.allItemsIdIs.push(result.data[i].id);
      }
      console.table(this.allItemsIdIs);
    }
  },
  methods: {
    ...mapMutations(["canUserAccessThisItem"]),
    ...mapActions(["redirectTo"]),
    goBack() {
      this.$router.push({
        name: "LocationMenu",
        params: { locationId: this.locationId },
      });
    },
    async canCurrentUserAccessThisLocation() {
      let result = await axios.get(
        `http://localhost:3000/locations?id=${this.deleteLocation}&userId=${this.userId}`
      );
      if (result.status == 200 && result.data.length > 0) {
        this.locationData = result.data;
        this.locName = this.locationData[0].name;
        this.locAddress = this.locationData[0].address;
      } else {
        this.redirectTo({ val: "home" });
      }
    },
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `http://localhost:3000/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200) {
        locDetails = result.data;
        if (result.data.length > 0) {
          this.locName = locDetails[0].name;
          this.locAddress = locDetails[0].address;
        }
      }
    },
    async deleteAllItems() {
      let allResults = [];
      for (var i = 0; i < this.allItemsIdIs.length; i++) {
        let result = await axios.delete(
          `http://localhost:3000/items/${this.allItemsIdIs[i]}`
        );
        if (result.status == 200) {
          allResults.push(true);
        } else {
          allResults.push(false);
        }
      }
      if (!allResults.includes(false)) {
        this.successMessage = " all items are deleted ..";
        setTimeout(() => {
          this.$router.push({
            name: "LocationMenu",
            params: { locationId: this.locationId },
          });
        }, 1000);
      } else {
        console.log("Something went error ");
        this.errorMessage = "Something went error";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
