<template>
  <div>
    <LogInForm />
  </div>
</template>
<script>
import LogInForm from "@/components/LogInForm.vue";
export default {
  name: "LogIn",
  components: {
    LogInForm,
  },
};
</script>
